import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ExperienciesComponent } from './components/experiencies/experiencies.component';
import { VisitesProgramadesComponent } from './components/visites-programades/visites-programades.component';
import { GrupsParticularsComponent } from './components/grups-particulars/grups-particulars.component';
import { ContacteComponent } from './components/contacte/contacte.component';
import { QuiSomComponent } from './components/qui-som/qui-som.component';
import { MissioVisioValorsComponent } from './components/missio-visio-valors/missio-visio-valors.component';
import { BlogComponent } from './components/blog/blog.component';
import { AvisLegalComponent } from './components/avis-legal/avis-legal.component';
import { SolsonaMonumentalComponent } from './components/solsona-monumental/solsona-monumental.component';
import { PouGelComponent } from './components/pou-gel/pou-gel.component';
import { TastaSolsonaComponent } from './components/tasta-solsona/tasta-solsona.component';
import { QuartoGegantsComponent } from './components/quarto-gegants/quarto-gegants.component';
import { GlobusPirineuComponent } from './components/globus-pirineu/globus-pirineu.component';
import { CellerMiracleComponent } from './components/celler-miracle/celler-miracle.component';
import { MuseuSolsonaComponent } from './components/museu-solsona/museu-solsona.component';
import { BusaComponent } from './components/busa/busa.component';
import { OliusComponent } from './components/olius/olius.component';
import { CampanarComponent } from './components/campanar/campanar.component';
import { ALaCartaComponent } from './components/a-la-carta/a-la-carta.component';
import { CatedralComponent } from './components/catedral/catedral.component';
import { NucliAnticComponent } from './components/nucli-antic/nucli-antic.component';
import { PoliticaComponent } from './components/politica/politica.component';
import { OrgueComponent } from './components/orgue/orgue.component';
import { MaratoTv3Component } from './components/marato-tv3/marato-tv3.component';
import { SenderismeCastellvellComponent } from './components/senderisme-castellvell/senderisme-castellvell.component';
import { GegantsCarnavalSolsonaComponent } from './components/gegants-carnaval-solsona/gegants-carnaval-solsona.component';
import { NocturnesOliusComponent } from './components/nocturnes-olius/nocturnes-olius.component';
import { TeatralitzadesLlorComponent } from './components/teatralitzades-llor/teatralitzades-llor.component';
import { SantuariMiracleComponent } from './components/santuari-miracle/santuari-miracle.component';
import { NocturnesSolsonaEducacioComponent } from './components/nocturnes-solsona-educacio/nocturnes-solsona-educacio.component';
import { NocturnaCastellvellComponent } from './components/nocturna-castellvell/nocturna-castellvell.component'
import { NocturnaDeuAnysComponent } from './components/nocturna-deu-anys/nocturna-deu-anys.component'
import { NocturnaCarnavalComponent } from './components/nocturna-carnaval/nocturna-carnaval.component'
import { TorreVallferosaComponent } from './components/torre-vallferosa/torre-vallferosa.component'
import { SantuariPinosTorreArdevolComponent } from './components/santuari-pinos-torre-ardevol/santuari-pinos-torre-ardevol.component';
import { LoCalBoigComponent } from './components/lo-cal-boig/lo-cal-boig.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: ':lang/home',
    component: HomeComponent,
  },
  {
    path: 'experiencies',
    component: ExperienciesComponent,
  },
  {
    path: ':lang/experiencies',
    component: ExperienciesComponent,
  },
  {
    path: 'visites-programades',
    component: VisitesProgramadesComponent,
  },
  {
    path: ':lang/visites-programades',
    component: VisitesProgramadesComponent,
  },
  {
    path: 'grups-particulars',
    component: GrupsParticularsComponent,
  },
  {
    path: ':lang/grups-particulars',
    component: GrupsParticularsComponent,
  },
  {
    path: 'contacte',
    component: ContacteComponent,
  },
  {
    path: ':lang/contacte',
    component: ContacteComponent,
  },
  {
    path: 'qui-som',
    component: QuiSomComponent,
  },
  {
    path: ':lang/qui-som',
    component: QuiSomComponent,
  },
  {
    path: 'missio-visio-valors',
    component: MissioVisioValorsComponent,
  },
  {
    path: ':lang/missio-visio-valors',
    component: MissioVisioValorsComponent,
  },
  {
    path: 'blog',
    component: BlogComponent,
  },
  {
    path: ':lang/blog',
    component: BlogComponent,
  },
  {
    path: 'avis-legal',
    component: AvisLegalComponent,
  },
  {
    path: ':lang/avis-legal',
    component: AvisLegalComponent,
  },
  {
    path: 'politica',
    component: PoliticaComponent,
  },
  {
    path: ':lang/politica',
    component: PoliticaComponent,
  },
  {
    path: 'solsona-monumental',
    component: SolsonaMonumentalComponent,
  },
  {
    path: ':lang/solsona-monumental',
    component: SolsonaMonumentalComponent,
  },
  {
    path: 'pou-gel',
    component: PouGelComponent,
  },
  {
    path: ':lang/pou-gel',
    component: PouGelComponent,
  },
  {
    path: 'tasta-solsona',
    component: TastaSolsonaComponent,
  },
  {
    path: ':lang/tasta-solsona',
    component: TastaSolsonaComponent,
  },
  {
    path: 'quarto-gegants',
    component: QuartoGegantsComponent,
  },
  {
    path: ':lang/quarto-gegants',
    component: QuartoGegantsComponent,
  },
  {
    path: 'globus-pirineu',
    component: GlobusPirineuComponent,
  },
  {
    path: ':lang/globus-pirineu',
    component: GlobusPirineuComponent,
  },
  {
    path: 'celler-miracle',
    component: CellerMiracleComponent,
  },
  {
    path: ':lang/celler-miracle',
    component: CellerMiracleComponent,
  },
  {
    path: 'museu-solsona',
    component: MuseuSolsonaComponent,
  },
  {
    path: ':lang/museu-solsona',
    component: MuseuSolsonaComponent,
  },
  {
    path: 'busa',
    component: BusaComponent,
  },
  {
    path: ':lang/busa',
    component: BusaComponent,
  },
  {
    path: 'olius',
    component: OliusComponent,
  },
  {
    path: ':lang/olius',
    component: OliusComponent,
  },
  {
    path: 'campanar',
    component: CampanarComponent,
  },
  {
    path: ':lang/campanar',
    component: CampanarComponent,
  },
  {
    path: 'orgue',
    component: OrgueComponent,
  },
  {
    path: ':lang/orgue',
    component: OrgueComponent,
  },
  {
    path: 'a-la-carta',
    component: ALaCartaComponent,
  },
  {
    path: ':lang/a-la-carta',
    component: ALaCartaComponent,
  },
  {
    path: 'catedral',
    component: CatedralComponent,
  },
  {
    path: ':lang/catedral',
    component: CatedralComponent,
  },
  {
    path: 'nucli-antic',
    component: NucliAnticComponent,
  },
  {
    path: ':lang/nucli-antic',
    component: NucliAnticComponent,
  },
  {
    path: 'marato-tv3',
    component: MaratoTv3Component,
  },
  {
    path: ':lang/marato-tv3',
    component: MaratoTv3Component,
  },
  {
    path: 'senderisme-castellvell',
    component: SenderismeCastellvellComponent,
  },
  {
    path: ':lang/senderisme-castellvell',
    component: SenderismeCastellvellComponent,
  },
  {
    path: 'gegants-carnaval-solsona',
    component: GegantsCarnavalSolsonaComponent,
  },
  {
    path: ':lang/gegants-carnaval-solsona',
    component: GegantsCarnavalSolsonaComponent,
  },
  {
    path: 'nocturnes-olius',
    component: NocturnesOliusComponent,
  },
  {
    path: ':lang/nocturnes-olius',
    component: NocturnesOliusComponent,
  },
  {
    path: 'teatralitzades-llor',
    component: TeatralitzadesLlorComponent,
  },
  {
    path: ':lang/teatralitzades-llor',
    component: TeatralitzadesLlorComponent,
  },
  {
    path: 'santuari-miracle',
    component: SantuariMiracleComponent,
  },
  {
    path: ':lang/santuari-miracle',
    component: SantuariMiracleComponent,
  },
  {
    path: 'nocturnes-solsona-educacio',
    component: NocturnesSolsonaEducacioComponent,
  },
  {
    path: ':lang/nocturnes-solsona-educacio',
    component: NocturnesSolsonaEducacioComponent,
  },
  {
    path: 'nocturna-castellvell',
    component: NocturnaCastellvellComponent,
  },
  {
    path: ':lang/nocturna-castellvell',
    component: NocturnaCastellvellComponent,
  },
  {
    path: 'nocturna-deu-anys',
    component: NocturnaDeuAnysComponent,
  },
  {
    path: ':lang/nocturna-deu-anys',
    component: NocturnaDeuAnysComponent,
  },
  {
    path: 'nocturna-carnaval',
    component: NocturnaCarnavalComponent,
  },
  {
    path: ':lang/nocturna-carnaval',
    component: NocturnaCarnavalComponent,
  },
  {
    path: 'torre-vallferosa',
    component: TorreVallferosaComponent,
  },
  {
    path: ':lang/torre-vallferosa',
    component: TorreVallferosaComponent,
  },
  {
    path: 'santuari-pinos-torre-ardevol',
    component: SantuariPinosTorreArdevolComponent,
  },
  {
    path: ':lang/santuari-pinos-torre-ardevol',
    component: SantuariPinosTorreArdevolComponent,
  },
  {
    path: 'lo-cal-boig',
    component: LoCalBoigComponent,
  },
  {
    path: ':lang/lo-cal-boig',
    component: LoCalBoigComponent,
  },
  {
    path: ':lang/page-not-found',
    component: PageNotFoundComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }  // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
