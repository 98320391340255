import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor() { }
  log(msg: any) {
    console.log(msg);
  }
  error(msg: any) {
    console.error(msg);
  }
  warn(msg: any) {
    console.warn(msg);
  }

  /* Mail.js */
  initMailForm() {
    setTimeout(function() {
      //Contact
      //@ts-ignore
      $('form.contactForm').submit(function() {

        //@ts-ignore
        var f = $(this).find('.form-group'),
          ferror = false,
          emailExp = /^[^\s()<>@,;:\/]+@\w[\w\.-]+\.[a-z]{2,}$/i;

        f.children('input').each(function() { // run all inputs

          //@ts-ignore
          var i = $(this); // current input
          var rule = i.attr('data-rule');

          if (rule !== undefined) {
            var ierror = false; // error flag for current input
            var pos = rule.indexOf(':', 0);
            if (pos >= 0) {
              var exp = rule.substr(pos + 1, rule.length);
              rule = rule.substr(0, pos);
            } else {
              rule = rule.substr(pos + 1, rule.length);
            }

            switch (rule) {
              case 'required':
                if (i.val() === '') {
                  ferror = ierror = true;
                }
                break;

              case 'minlen':
                // @ts-ignore
                if (i.val().length < parseInt(exp)) {
                  ferror = ierror = true;
                }
                break;

              case 'email':
                // @ts-ignore
                if (!emailExp.test(i.val())) {
                  ferror = ierror = true;
                }
                break;

              case 'checked':
                if (!i.is(':checked')) {
                  ferror = ierror = true;
                }
                break;

              case 'regexp':
                // @ts-ignore
                exp = new RegExp(exp);
                // @ts-ignore
                if (!exp.test(i.val())) {
                  ferror = ierror = true;
                }
                break;
            }
            // @ts-ignore
            i.next('.validation').html((ierror ? (i.attr('title') !== undefined ? i.attr('title') : 'wrong Input') : '')).show('blind');
          }
        });
        f.children('textarea').each(function() { // run all inputs

          //@ts-ignore
          var i = $(this); // current input
          var rule = i.attr('data-rule');

          if (rule !== undefined) {
            var ierror = false; // error flag for current input
            var pos = rule.indexOf(':', 0);
            if (pos >= 0) {
              var exp = rule.substr(pos + 1, rule.length);
              rule = rule.substr(0, pos);
            } else {
              rule = rule.substr(pos + 1, rule.length);
            }

            switch (rule) {
              case 'required':
                if (i.val() === '') {
                  ferror = ierror = true;
                }
                break;

              case 'minlen':
                // @ts-ignore
                if (i.val().length < parseInt(exp)) {
                  ferror = ierror = true;
                }
                break;
            }
            // @ts-ignore
            i.next('.validation').html((ierror ? (i.attr('title') != undefined ? i.attr('title') : 'wrong Input') : '')).show('blind');
          }
        });
        if (ferror) return false;
        else {

          // Activar Preloader abans d'enviar
          //@ts-ignore
          $('.preloader').fadeIn(100);
          //@ts-ignore
          $("#loader").addClass("atenuat");

          //EmailJS
          var template_params = {
            //@ts-ignore
            "email": $("#email").val(),
            //@ts-ignore
            "nom": $("#name").val(),
            //@ts-ignore
            "telefon": $("#tlf").val(),
            //@ts-ignore
            "activitat": $("#activitat").val(),
            //@ts-ignore
            "data_c": $("#date").val(),
            //@ts-ignore
            "persones": $("#pax").val(),
            //@ts-ignore
            "missatge": $("#msg").val()
          }


          var service_id = "default_service";
          var template_id = "reserva";
          // @ts-ignore
          emailjs.send(service_id, template_id, template_params)
            .then(function(response) {
              // console.log("Resposta EmailJS= " + response.text);
              if (response.text === "OK") {
                //    console.log("es mostra SENDMESSAGE");
                //@ts-ignore
                $("#sendmessage").addClass("show");
                //@ts-ignore
                $("#errormessage").removeClass("show");
                //@ts-ignore
                $('.contactForm').find("input, textarea").val("");
                // Desactivar Preloader
                //@ts-ignore
                $('.preloader').fadeOut(500);
                //@ts-ignore
                $("#loader").removeClass("atenuat");
              }
            }, function(err) {
              //    console.log("es mostra ERROR");
              //@ts-ignore
              $("#sendmessage").removeClass("show");
              //@ts-ignore
              $("#errormessage").addClass("show");
              // Desactivar Preloader
              //@ts-ignore
              $('.preloader').fadeOut(500);
              //@ts-ignore
              $("#loader").removeClass("atenuat");
            });
        }
        return false;
      });

    }, 1000);
  }


    /* Mail.js */
    initMailFormNewsLatter() {
      setTimeout(function() {
        //Contact
        //@ts-ignore
        $('form.contactForm').submit(function() {
  
          //@ts-ignore
          var f = $(this).find('.form-group'),
            ferror = false,
            emailExp = /^[^\s()<>@,;:\/]+@\w[\w\.-]+\.[a-z]{2,}$/i;
  
          f.children('input').each(function() { // run all inputs
  
            //@ts-ignore
            var i = $(this); // current input
            var rule = i.attr('data-rule');
  
            if (rule !== undefined) {
              var ierror = false; // error flag for current input
              var pos = rule.indexOf(':', 0);
              if (pos >= 0) {
                var exp = rule.substr(pos + 1, rule.length);
                rule = rule.substr(0, pos);
              } else {
                rule = rule.substr(pos + 1, rule.length);
              }
  
              switch (rule) {
                case 'required':
                  if (i.val() === '') {
                    ferror = ierror = true;
                  }
                  break;
  
                case 'minlen':
                  // @ts-ignore
                  if (i.val().length < parseInt(exp)) {
                    ferror = ierror = true;
                  }
                  break;
  
                case 'email':
                  // @ts-ignore
                  if (!emailExp.test(i.val())) {
                    ferror = ierror = true;
                  }
                  break;
  
                case 'checked':
                  if (!i.is(':checked')) {
                    ferror = ierror = true;
                  }
                  break;
  
                case 'regexp':
                  // @ts-ignore
                  exp = new RegExp(exp);
                  // @ts-ignore
                  if (!exp.test(i.val())) {
                    ferror = ierror = true;
                  }
                  break;
              }
              // @ts-ignore
              i.next('.validation').html((ierror ? (i.attr('title') !== undefined ? i.attr('title') : 'wrong Input') : '')).show('blind');
            }
          });
          f.children('textarea').each(function() { // run all inputs
  
            //@ts-ignore
            var i = $(this); // current input
            var rule = i.attr('data-rule');
  
            if (rule !== undefined) {
              var ierror = false; // error flag for current input
              var pos = rule.indexOf(':', 0);
              if (pos >= 0) {
                var exp = rule.substr(pos + 1, rule.length);
                rule = rule.substr(0, pos);
              } else {
                rule = rule.substr(pos + 1, rule.length);
              }
  
              switch (rule) {
                case 'required':
                  if (i.val() === '') {
                    ferror = ierror = true;
                  }
                  break;
  
                case 'minlen':
                  // @ts-ignore
                  if (i.val().length < parseInt(exp)) {
                    ferror = ierror = true;
                  }
                  break;
              }
              // @ts-ignore
              i.next('.validation').html((ierror ? (i.attr('title') != undefined ? i.attr('title') : 'wrong Input') : '')).show('blind');
            }
          });
          if (ferror) return false;
          else {
  
            // Activar Preloader abans d'enviar
            //@ts-ignore
            $('.preloader').fadeIn(100);
            //@ts-ignore
            $("#loader").addClass("atenuat");
  
            //EmailJS
            var template_params = {
              //@ts-ignore
              "email": $("#email").val(),
              //@ts-ignore
              "nom": $("#name").val(),
              //@ts-ignore
            }
  
  
            var service_id = "default_service";
            var template_id = "contactar";
            // @ts-ignore
            emailjs.send(service_id, template_id, template_params)
              .then(function(response) {
                // console.log("Resposta EmailJS= " + response.text);
                if (response.text === "OK") {
                  //    console.log("es mostra SENDMESSAGE");
                  //@ts-ignore
                  $("#sendmessage").addClass("show");
                  //@ts-ignore
                  $("#errormessage").removeClass("show");
                  //@ts-ignore
                  $('.contactForm').find("input, textarea").val("");
                  // Desactivar Preloader
                  //@ts-ignore
                  $('.preloader').fadeOut(500);
                  //@ts-ignore
                  $("#loader").removeClass("atenuat");
                }
              }, function(err) {
                //    console.log("es mostra ERROR");
                //@ts-ignore
                $("#sendmessage").removeClass("show");
                //@ts-ignore
                $("#errormessage").addClass("show");
                // Desactivar Preloader
                //@ts-ignore
                $('.preloader').fadeOut(500);
                //@ts-ignore
                $("#loader").removeClass("atenuat");
              });
          }
          return false;
        });
  
      }, 1000);
    }


  /* Permet slider que es passa amb el dit des de mòbil */
  initSwipeable() {
    setTimeout(function() {
      // swiper slider
      //initialize swiper when document ready
      //@ts-ignore
      var mySwiper = new Swiper('.swiper-container', {
        // Optional parameters
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true,
        },
        direction: 'horizontal',
        loop: true
      })
      //@ts-ignore
      var mySwiper = new Swiper('.swiper-container-opina', {
        autoHeight: true, //enable auto height
        spaceBetween: 10000,
        // Optional parameters
        autoplay: {
          delay: 10000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true,
        },
        direction: 'horizontal',
        loop: true
      })
      //@ts-ignore
      var mySwiper = new Swiper('.swiper-container-fade', {
        // Optional parameters
        effect: 'fade',
        spaceBetween: 500,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true,
        },
        direction: 'horizontal',
        loop: true
      })
      //@ts-ignore
      var mySwiper = new Swiper('.swiper-container-cube', {
        // Optional parameters
        effect: 'cube',
        grabCursor: true,
        cubeEffect: {
          shadow: true,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94,
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true,
        },
        direction: 'horizontal',
        loop: true
      })
      //@ts-ignore
      var mySwiper = new Swiper('.swiper-container-flip', {
        // Optional parameters
        effect: 'flip',
        grabCursor: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true,
        },
        direction: 'horizontal',
        loop: true
      })
      //@ts-ignore
      var mySwiper = new Swiper('.swiper-container-coverflow', {
        // Optional parameters
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 3,
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true,
        },
        direction: 'horizontal',
        loop: true
      })

    }, 500);
  }

}
