import { BrowserModule, Title, Meta } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { Location } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';

// Components & routing
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

// Directives
import { MoveToHeadDirective } from './directives/move-to-head/move-to-head.directive';

// Pipes
import { SafeUrlPipe } from './pipes/safe-url/safe-url.pipe';

// Services
import { LocalizationService } from './services/localization/localization.service';
import { SeoService } from './services/seo/seo.service';
import { LoggerService } from './services/logger.service';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ExperienciesComponent } from './components/experiencies/experiencies.component';
import { VisitesProgramadesComponent } from './components/visites-programades/visites-programades.component';
import { GrupsParticularsComponent } from './components/grups-particulars/grups-particulars.component';
import { ContacteComponent } from './components/contacte/contacte.component';
import { QuiSomComponent } from './components/qui-som/qui-som.component';
import { MissioVisioValorsComponent } from './components/missio-visio-valors/missio-visio-valors.component';
import { BlogComponent } from './components/blog/blog.component';
import { AvisLegalComponent } from './components/avis-legal/avis-legal.component';
import { SolsonaMonumentalComponent } from './components/solsona-monumental/solsona-monumental.component';
import { PouGelComponent } from './components/pou-gel/pou-gel.component';
import { TastaSolsonaComponent } from './components/tasta-solsona/tasta-solsona.component';
import { QuartoGegantsComponent } from './components/quarto-gegants/quarto-gegants.component';
import { GlobusPirineuComponent } from './components/globus-pirineu/globus-pirineu.component';
import { CellerMiracleComponent } from './components/celler-miracle/celler-miracle.component';
import { MuseuSolsonaComponent } from './components/museu-solsona/museu-solsona.component';
import { BusaComponent } from './components/busa/busa.component';
import { OliusComponent } from './components/olius/olius.component';
import { CampanarComponent } from './components/campanar/campanar.component';
import { ALaCartaComponent } from './components/a-la-carta/a-la-carta.component';
import { CatedralComponent } from './components/catedral/catedral.component';
import { NucliAnticComponent } from './components/nucli-antic/nucli-antic.component';
import { PoliticaComponent } from './components/politica/politica.component';

import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { OrgueComponent } from './components/orgue/orgue.component';
import { MaratoTv3Component } from './components/marato-tv3/marato-tv3.component';
import { SenderismeCastellvellComponent } from './components/senderisme-castellvell/senderisme-castellvell.component';
import { GegantsCarnavalSolsonaComponent } from './components/gegants-carnaval-solsona/gegants-carnaval-solsona.component';
import { NocturnesOliusComponent } from './components/nocturnes-olius/nocturnes-olius.component';
import { TeatralitzadesLlorComponent } from './components/teatralitzades-llor/teatralitzades-llor.component';
import { SantuariMiracleComponent } from './components/santuari-miracle/santuari-miracle.component';
import { NocturnesSolsonaEducacioComponent } from './components/nocturnes-solsona-educacio/nocturnes-solsona-educacio.component';
import { NocturnaCastellvellComponent } from './components/nocturna-castellvell/nocturna-castellvell.component';
import { NocturnaDeuAnysComponent } from './components/nocturna-deu-anys/nocturna-deu-anys.component';
import { NocturnaCarnavalComponent } from './components/nocturna-carnaval/nocturna-carnaval.component';
import { TorreVallferosaComponent } from './components/torre-vallferosa/torre-vallferosa.component';
import { SantuariPinosTorreArdevolComponent } from './components/santuari-pinos-torre-ardevol/santuari-pinos-torre-ardevol.component';
import { LoCalBoigComponent } from './components/lo-cal-boig/lo-cal-boig.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PageNotFoundComponent,
    MoveToHeadDirective,
    SafeUrlPipe,
    HeaderComponent,
    FooterComponent,
    ExperienciesComponent,
    VisitesProgramadesComponent,
    GrupsParticularsComponent,
    ContacteComponent,
    QuiSomComponent,
    MissioVisioValorsComponent,
    BlogComponent,
    AvisLegalComponent,
    SolsonaMonumentalComponent,
    PouGelComponent,
    TastaSolsonaComponent,
    QuartoGegantsComponent,
    GlobusPirineuComponent,
    CellerMiracleComponent,
    MuseuSolsonaComponent,
    BusaComponent,
    OliusComponent,
    CampanarComponent,
    ALaCartaComponent,
    CatedralComponent,
    NucliAnticComponent,
    PoliticaComponent,
    OrgueComponent,
    MaratoTv3Component,
    SenderismeCastellvellComponent,
    GegantsCarnavalSolsonaComponent,
    NocturnesOliusComponent,
    TeatralitzadesLlorComponent,
    SantuariMiracleComponent,
    NocturnesSolsonaEducacioComponent,
    NocturnaCastellvellComponent,
    NocturnaDeuAnysComponent,
    NocturnaCarnavalComponent,
    TorreVallferosaComponent,
    SantuariPinosTorreArdevolComponent,
    LoCalBoigComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'ca',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, //firestore
    AngularFireAuthModule, // auth
    AngularFireStorageModule, // storage
    AppRoutingModule
  ],
  providers: [
    Title,
    Meta,
    LoggerService,
    LocalizationService,
    { provide: 'googleTagManagerId', useValue: 'GTM-MR6DDN4' },
    SeoService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
